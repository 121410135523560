@import url("https://fonts.googleapis.com/css?family=Montserrat:300,400,700");
.center {
  text-align: center; }

.left {
  text-align: left; }

.table th {
  text-align: center; }

.table td {
  width: 50%; }

.table_blockchart {
  width: 80%;
  margin: auto;
  display: inline-block; }
