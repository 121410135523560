@import url("https://fonts.googleapis.com/css?family=Montserrat:300,400,700");
header, .page-header {
  background: linear-gradient(to right, rgba(20, 85, 143, 0.8), rgba(20, 85, 143, 0.8), rgba(67, 149, 111, 0.4)), url("/images/dese-header-image.jpg");
  background-color: rgba(20, 85, 143, 0.8);
  background-size: cover;
  background-position: center;
  padding: 30px 0;
  margin: 0 auto;
  min-height: 300px;
  border: 0;
  color: #ffffff; }
  header h1, .page-header h1 {
    font-size: 5rem;
    font-family: "Montserrat", "Helvetica Neue", "Source Sans Pro", "Helvetica", "Arial", sans-serif; }
  header h3, .page-header h3 {
    font-weight: 400;
    font-size: 1.8rem; }
