.social-media {
  margin-top: 10px; }

.Demo__some-network {
  vertical-align: top;
  display: inline-block;
  margin-right: 20px;
  text-align: center; }

.Demo__some-network__share-count {
  margin-top: 3px;
  font-size: 9px; }

.Demo__some-network__share-button {
  cursor: pointer; }

.Demo__some-network__share-button:hover:not(:active) {
  opacity: 0.75; }
