@import url("https://fonts.googleapis.com/css?family=Montserrat:300,400,700");
.section-divider-title {
  color: #535353;
  position: relative;
  z-index: 1;
  margin-bottom: 5px; }
  .section-divider-title:before {
    border-top: 2px solid #dcdcdc;
    content: "";
    margin: 0 auto;
    /* this centers the line to the full width specified */
    position: absolute;
    /* positioning must be absolute here, and relative positioning must be applied to the parent */
    top: 50%;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    z-index: -1; }
  .section-divider-title span {
    /* to hide the lines from behind the text, you have to set the background color the same as the container */
    background: #f2f2f2;
    padding: 0 15px; }
