@import url("https://fonts.googleapis.com/css?family=Montserrat:300,400,700");
.disableClick {
  pointer-events: none;
  color: #535353;
  border-color: #535353; }

.btn-primary {
  color: #14558f;
  background-color: #ffffff;
  border-color: #14558f;
  margin: 6px 3px;
  padding: 10px 20px;
  border-radius: 0; }

.btn-primary:focus,
.btn-primary.focus {
  color: #ffffff;
  background-color: #14558f;
  border-color: #14558f; }

.btn-primary:hover {
  color: #ffffff;
  background-color: #14558f;
  border-color: #14558f; }

.btn-primary:active:hover,
.btn-primary.active:hover,
.open > .dropdown-toggle.btn-primary:hover,
.btn-primary:active:focus,
.btn-primary.active:focus,
.open > .dropdown-toggle.btn-primary:focus,
.btn-primary:active.focus,
.btn-primary.active.focus,
.open > .dropdown-toggle.btn-primary.focus {
  color: #ffffff;
  background-color: #14558f;
  border-color: #14558f; }
