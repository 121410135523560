.btn-group {
  margin-bottom: 23px; }

@media only screen and (max-width: 767px) {
  .btn-group {
    display: block;
    margin-bottom: 10px; }
  .btn-group .btn {
    display: block;
    float: none;
    width: 100%; } }

@media only screen and (max-width: 1200px) and (min-width: 991px) {
  .btn-group {
    display: block; }
  .btn-group .btn {
    display: block;
    float: none;
    width: 100%; } }
