@import url("https://fonts.googleapis.com/css?family=Montserrat:300,400,700");
a.page-scroll {
  text-decoration: none; }
  a.page-scroll sup {
    font-size: 1.2rem;
    font-weight: 700;
    color: #14558f; }

.sources-section {
  color: #ffffff;
  padding: 30px;
  background-color: #141414;
  padding: 25px 0; }
  .sources-section a {
    color: #ffffff; }
    .sources-section a:active, .sources-section a:visited {
      color: #ffffff; }
    .sources-section a:hover, .sources-section a:focus {
      color: #ffffff; }
  .sources-section ol {
    padding-left: 20px; }
    .sources-section ol li:target span {
      color: #141414;
      background-color: #ffffff; }
      .sources-section ol li:target span a {
        color: #141414; }
    .sources-section ol li:target a {
      color: #ffffff; }
    .sources-section ol li i {
      font-size: 1.5rem; }
      .sources-section ol li i:hover {
        font-size: 1.6rem; }
