@import url("https://fonts.googleapis.com/css?family=Montserrat:300,400,700");
.navbar {
  margin: 0 auto;
  padding: 0;
  border-radius: 0;
  border: 0; }

.navbar-fixed-top {
  position: fixed;
  margin-top: 0;
  right: 0;
  left: 0;
  z-index: 1030; }

.navbar-collapse {
  display: none;
  padding: 0; }

.navbar-expand {
  display: block;
  padding: 0; }

.nav, .navbar-inverse, .navbar-header {
  color: #ffffff;
  background-color: #14558f;
  float: none; }

.navbar-toggle {
  display: block;
  float: right; }

.navbar-collapse {
  border-top: 1px solid transparent; }

.nav li {
  width: 100%;
  font-size: 1.8rem; }
  .nav li a {
    width: 100%;
    text-decoration: none;
    color: #a0a0a0;
    border-radius: 0;
    border: none;
    padding: 12px 15px 15px;
    text-align: center; }
    .nav li a.active, .nav li a:hover, .nav li a:active, .nav li a:focus {
      background-color: #ffffff;
      color: #14558f;
      border-radius: 0;
      border-bottom: 3px solid #FFB300;
      padding: 12px 15px 12px; }
  .nav li:last-child {
    border-top: 1px solid #a0a0a0;
    border-bottom: none;
    background-color: #212121; }
    .nav li:last-child a.active, .nav li:last-child a:hover, .nav li:last-child a:active, .nav li:last-child a:focus {
      color: #212121; }

.navbar-inverse .navbar-brand {
  color: #ffffff; }

.navbar-inverse .navbar-toggle {
  border: 1px solid #ffffff;
  color: #ffffff;
  border-radius: 0; }
  .navbar-inverse .navbar-toggle.active, .navbar-inverse .navbar-toggle:hover, .navbar-inverse .navbar-toggle:active {
    color: #ffffff;
    background-color: #141414;
    border: 1px solid #141414; }

#current-section {
  margin-top: 4px;
  display: block; }

@media all and (min-width: 1199px) {
  .nav {
    background-color: #ffffff;
    margin: 0 auto;
    text-align: center; }
    .nav li {
      width: 14%; }
      .nav li a {
        text-align: center;
        margin: 0 auto; }
        .nav li a.active, .nav li a:hover, .nav li a:active, .nav li a:focus {
          background-color: #14558f;
          color: #ffffff; }
      .nav li:first-child {
        width: 12%; }
      .nav li:last-child {
        width: 18%;
        padding-top: 0;
        margin-top: 0;
        border-top: none;
        border-left: 1px solid #a0a0a0;
        background-color: #f2f2f2; }
        .nav li:last-child a.active, .nav li:last-child a:hover, .nav li:last-child a:active, .nav li:last-child a:focus {
          background-color: #212121;
          color: #ffffff; }
  .navbar {
    padding: 0;
    background-color: #ffffff; }
  .navbar-fixed-top {
    position: fixed; }
  .navbar-collapse {
    display: inline;
    padding: 0; }
  .navbar-toggle {
    display: none; }
  #current-section {
    display: none; } }

.nav-tabs {
  padding-bottom: 1px;
  border-bottom: 1px solid #dcdcdc; }

.nav-item {
  padding: 0; }

.nav-item_last {
  border-top: none; }

.target-nav {
  padding-top: 50px; }
