@import url("https://fonts.googleapis.com/css?family=Montserrat:300,400,700");
.block-chart {
  width: 230px;
  height: 230px;
  display: inline-block; }

.block {
  float: left;
  height: 9%;
  width: 9%;
  margin: 0.5%;
  position: relative;
  background: none; }

.colorPerson_Yes {
  color: #ffffff;
  fill: #43956f; }

.colorPerson_No {
  color: #141414;
  fill: #dcdcdc; }
