@import url("https://fonts.googleapis.com/css?family=Montserrat:300,400,700");
.block-group {
  width: 100%;
  margin: 0 auto;
  display: inline;
  text-align: center; }

.highlight_Yes {
  color: #43956f;
  font-weight: 700; }

.highlight_No {
  color: #535353;
  font-weight: 700; }

.block-desc {
  padding: 0;
  text-align: left;
  vertical-align: text-bottom;
  margin: 15px 0 -15px; }
  .block-desc p {
    line-height: 1.3;
    font-size: 1.5rem; }

@media only screen and (min-width: 333px) {
  .block-desc {
    height: 6rem; } }

@media only screen and (min-width: 576px) {
  .block-desc {
    height: 5rem; } }

@media only screen and (min-width: 992px) {
  .block-desc {
    height: 8rem;
    margin: 0; } }
