@import url("https://fonts.googleapis.com/css?family=Montserrat:300,400,700");
.target-nav {
  padding-top: 80px; }

.grade_line {
  display: none; }

.vertical_space {
  display: block;
  height: 30px;
  width: 100%; }

@media all and (min-width: 992px) {
  .container .grade_main {
    width: 80%;
    float: right; }
  .container .vertical_space {
    display: none; } }
