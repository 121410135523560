@import url(https://fonts.googleapis.com/css?family=Montserrat:300,400,700);
@import url(https://fonts.googleapis.com/css?family=Montserrat:300,400,700);
@import url(https://fonts.googleapis.com/css?family=Montserrat:300,400,700);
@import url(https://fonts.googleapis.com/css?family=Montserrat:300,400,700);
@import url(https://fonts.googleapis.com/css?family=Montserrat:300,400,700);
@import url(https://fonts.googleapis.com/css?family=Montserrat:300,400,700);
@import url(https://fonts.googleapis.com/css?family=Montserrat:300,400,700);
@import url(https://fonts.googleapis.com/css?family=Montserrat:300,400,700);
@import url(https://fonts.googleapis.com/css?family=Montserrat:300,400,700);
@import url(https://fonts.googleapis.com/css?family=Montserrat:300,400,700);
@import url(https://fonts.googleapis.com/css?family=Montserrat:300,400,700);
@import url(https://fonts.googleapis.com/css?family=Montserrat:300,400,700);
@import url(https://fonts.googleapis.com/css?family=Montserrat:300,400,700);
@import url(https://fonts.googleapis.com/css?family=Montserrat:300,400,700);
@import url(https://fonts.googleapis.com/css?family=Montserrat:300,400,700);
@import url(https://fonts.googleapis.com/css?family=Montserrat:300,400,700);
@import url(https://fonts.googleapis.com/css?family=Montserrat:300,400,700);
@import url(https://fonts.googleapis.com/css?family=Montserrat:300,400,700);
@import url(https://fonts.googleapis.com/css?family=Montserrat:300,400,700);
header, .page-header {
  background: -webkit-linear-gradient(left, rgba(20, 85, 143, 0.8), rgba(20, 85, 143, 0.8), rgba(67, 149, 111, 0.4)), url("/images/dese-header-image.jpg");
  background: linear-gradient(to right, rgba(20, 85, 143, 0.8), rgba(20, 85, 143, 0.8), rgba(67, 149, 111, 0.4)), url("/images/dese-header-image.jpg");
  background-color: rgba(20, 85, 143, 0.8);
  background-size: cover;
  background-position: center;
  padding: 30px 0;
  margin: 0 auto;
  min-height: 300px;
  border: 0;
  color: #ffffff; }
  header h1, .page-header h1 {
    font-size: 5rem;
    font-family: "Montserrat", "Helvetica Neue", "Source Sans Pro", "Helvetica", "Arial", sans-serif; }
  header h3, .page-header h3 {
    font-weight: 400;
    font-size: 1.8rem; }

.social-media {
  margin-top: 10px; }

.Demo__some-network {
  vertical-align: top;
  display: inline-block;
  margin-right: 20px;
  text-align: center; }

.Demo__some-network__share-count {
  margin-top: 3px;
  font-size: 9px; }

.Demo__some-network__share-button {
  cursor: pointer; }

.Demo__some-network__share-button:hover:not(:active) {
  opacity: 0.75; }

footer {
  background-color: #f2f2f2;
  padding: 20px 0; }
  footer address {
    vertical-align: center;
    margin: 20px 0 0; }

a.page-scroll {
  text-decoration: none; }
  a.page-scroll sup {
    font-size: 1.2rem;
    font-weight: 700;
    color: #14558f; }

.sources-section {
  color: #ffffff;
  padding: 30px;
  background-color: #141414;
  padding: 25px 0; }
  .sources-section a {
    color: #ffffff; }
    .sources-section a:active, .sources-section a:visited {
      color: #ffffff; }
    .sources-section a:hover, .sources-section a:focus {
      color: #ffffff; }
  .sources-section ol {
    padding-left: 20px; }
    .sources-section ol li:target span {
      color: #141414;
      background-color: #ffffff; }
      .sources-section ol li:target span a {
        color: #141414; }
    .sources-section ol li:target a {
      color: #ffffff; }
    .sources-section ol li i {
      font-size: 1.5rem; }
      .sources-section ol li i:hover {
        font-size: 1.6rem; }

.disableClick {
  pointer-events: none;
  color: #535353;
  border-color: #535353; }

.btn-primary {
  color: #14558f;
  background-color: #ffffff;
  border-color: #14558f;
  margin: 6px 3px;
  padding: 10px 20px;
  border-radius: 0; }

.btn-primary:focus,
.btn-primary.focus {
  color: #ffffff;
  background-color: #14558f;
  border-color: #14558f; }

.btn-primary:hover {
  color: #ffffff;
  background-color: #14558f;
  border-color: #14558f; }

.btn-primary:active:hover,
.btn-primary.active:hover,
.open > .dropdown-toggle.btn-primary:hover,
.btn-primary:active:focus,
.btn-primary.active:focus,
.open > .dropdown-toggle.btn-primary:focus,
.btn-primary:active.focus,
.btn-primary.active.focus,
.open > .dropdown-toggle.btn-primary.focus {
  color: #ffffff;
  background-color: #14558f;
  border-color: #14558f; }

.center {
  text-align: center; }

.left {
  text-align: left; }

.table th {
  text-align: center; }

.table td {
  width: 50%; }

.table_blockchart {
  width: 80%;
  margin: auto;
  display: inline-block; }

.legend-text {
  font-size: 1.2rem; }

.legend-icon {
  border: 1px solid #535353;
  height: 10px;
  width: 10px;
  display: inline-block; }

.table td.fit, .table th.fit {
  white-space: nowrap;
  width: 1%; }

.block-chart {
  width: 230px;
  height: 230px;
  display: inline-block; }

.block {
  float: left;
  height: 9%;
  width: 9%;
  margin: 0.5%;
  position: relative;
  background: none; }

.colorPerson_Yes {
  color: #ffffff;
  fill: #43956f; }

.colorPerson_No {
  color: #141414;
  fill: #dcdcdc; }

.custom-tooltip {
  background-color: #ffffff;
  padding: 10px;
  border: 1px solid #535353;
  text-align: left;
  max-width: 330px; }

h2.grade-header {
  font-family: "Montserrat", "Helvetica Neue", "Source Sans Pro", "Helvetica", "Arial", sans-serif;
  font-size: 2.7rem;
  background-color: #FFB300;
  color: #ffffff;
  padding: 15px 10px;
  text-align: center; }

h3.chart-title {
  font-family: "Montserrat", "Helvetica Neue", "Source Sans Pro", "Helvetica", "Arial", sans-serif;
  font-weight: 700;
  font-size: 2.3rem;
  border-bottom: 3px solid #FFB300;
  color: #14558f;
  padding-bottom: 10px;
  display: inline-block;
  line-height: 1.3;
  width: 100%;
  text-align: center; }
  h3.chart-title span {
    font-weight: 300;
    font-size: 2.3rem; }

@media all and (min-width: 992px) {
  h2.grade-header {
    font-size: 2.7rem;
    background-color: #FFB300;
    color: #ffffff;
    padding: 25px 5%;
    margin-bottom: 30px; }
    h2.grade-header span {
      font-size: 5rem; }
  h3.chart-title {
    line-height: 1.5;
    border-top: 7px solid #FFB300;
    border-bottom: 7px solid #FFB300;
    padding: 23px 0 23px 20px;
    font-size: 2.7rem;
    text-align: left; }
    h3.chart-title span {
      display: block; } }

.target-nav {
  padding-top: 80px; }

.grade_line {
  display: none; }

.vertical_space {
  display: block;
  height: 30px;
  width: 100%; }

@media all and (min-width: 992px) {
  .container .grade_main {
    width: 80%;
    float: right; }
  .container .vertical_space {
    display: none; } }

a.download {
  font-size: 1.2rem;
  text-align: center; }

.btn-group {
  margin-bottom: 23px; }

@media only screen and (max-width: 767px) {
  .btn-group {
    display: block;
    margin-bottom: 10px; }
  .btn-group .btn {
    display: block;
    float: none;
    width: 100%; } }

@media only screen and (max-width: 1200px) and (min-width: 991px) {
  .btn-group {
    display: block; }
  .btn-group .btn {
    display: block;
    float: none;
    width: 100%; } }

.block-group-item {
  display: inline-block;
  margin: 5px; }

.block-count-chart {
  width: 220px;
  height: 110px;
  position: relative; }

.blockcount {
  float: left;
  height: 6.6%;
  width: 3.3%;
  margin: 0.5%;
  position: relative;
  opacity: 1; }

.color_Yes {
  color: #ffffff;
  background: #43956f; }

.color_No {
  color: #141414;
  background: #dcdcdc; }

.color_alt {
  color: #141414;
  background: rgba(67, 149, 111, 0.4); }

.block-group {
  width: 100%;
  margin: 0 auto;
  display: inline;
  text-align: center; }

.highlight_Yes {
  color: #43956f;
  font-weight: 700; }

.highlight_No {
  color: #535353;
  font-weight: 700; }

.block-desc {
  padding: 0;
  text-align: left;
  vertical-align: text-bottom;
  margin: 15px 0 -15px; }
  .block-desc p {
    line-height: 1.3;
    font-size: 1.5rem; }

@media only screen and (min-width: 333px) {
  .block-desc {
    height: 6rem; } }

@media only screen and (min-width: 576px) {
  .block-desc {
    height: 5rem; } }

@media only screen and (min-width: 992px) {
  .block-desc {
    height: 8rem;
    margin: 0; } }

.navbar {
  margin: 0 auto;
  padding: 0;
  border-radius: 0;
  border: 0; }

.navbar-fixed-top {
  position: fixed;
  margin-top: 0;
  right: 0;
  left: 0;
  z-index: 1030; }

.navbar-collapse {
  display: none;
  padding: 0; }

.navbar-expand {
  display: block;
  padding: 0; }

.nav, .navbar-inverse, .navbar-header {
  color: #ffffff;
  background-color: #14558f;
  float: none; }

.navbar-toggle {
  display: block;
  float: right; }

.navbar-collapse {
  border-top: 1px solid transparent; }

.nav li {
  width: 100%;
  font-size: 1.8rem; }
  .nav li a {
    width: 100%;
    text-decoration: none;
    color: #a0a0a0;
    border-radius: 0;
    border: none;
    padding: 12px 15px 15px;
    text-align: center; }
    .nav li a.active, .nav li a:hover, .nav li a:active, .nav li a:focus {
      background-color: #ffffff;
      color: #14558f;
      border-radius: 0;
      border-bottom: 3px solid #FFB300;
      padding: 12px 15px 12px; }
  .nav li:last-child {
    border-top: 1px solid #a0a0a0;
    border-bottom: none;
    background-color: #212121; }
    .nav li:last-child a.active, .nav li:last-child a:hover, .nav li:last-child a:active, .nav li:last-child a:focus {
      color: #212121; }

.navbar-inverse .navbar-brand {
  color: #ffffff; }

.navbar-inverse .navbar-toggle {
  border: 1px solid #ffffff;
  color: #ffffff;
  border-radius: 0; }
  .navbar-inverse .navbar-toggle.active, .navbar-inverse .navbar-toggle:hover, .navbar-inverse .navbar-toggle:active {
    color: #ffffff;
    background-color: #141414;
    border: 1px solid #141414; }

#current-section {
  margin-top: 4px;
  display: block; }

@media all and (min-width: 1199px) {
  .nav {
    background-color: #ffffff;
    margin: 0 auto;
    text-align: center; }
    .nav li {
      width: 14%; }
      .nav li a {
        text-align: center;
        margin: 0 auto; }
        .nav li a.active, .nav li a:hover, .nav li a:active, .nav li a:focus {
          background-color: #14558f;
          color: #ffffff; }
      .nav li:first-child {
        width: 12%; }
      .nav li:last-child {
        width: 18%;
        padding-top: 0;
        margin-top: 0;
        border-top: none;
        border-left: 1px solid #a0a0a0;
        background-color: #f2f2f2; }
        .nav li:last-child a.active, .nav li:last-child a:hover, .nav li:last-child a:active, .nav li:last-child a:focus {
          background-color: #212121;
          color: #ffffff; }
  .navbar {
    padding: 0;
    background-color: #ffffff; }
  .navbar-fixed-top {
    position: fixed; }
  .navbar-collapse {
    display: inline;
    padding: 0; }
  .navbar-toggle {
    display: none; }
  #current-section {
    display: none; } }

.nav-tabs {
  padding-bottom: 1px;
  border-bottom: 1px solid #dcdcdc; }

.nav-item {
  padding: 0; }

.nav-item_last {
  border-top: none; }

.target-nav {
  padding-top: 50px; }

.list-icon {
  color: #43956f;
  margin-right: 10px; }

ul.icon-list {
  list-style: none;
  margin-left: 0;
  padding-left: 2.5rem;
  text-indent: -1.7rem; }
  ul.icon-list li {
    font-size: 1.8rem;
    padding-left: 1rem;
    margin: 0.8rem 0; }

.full-width-alt {
  background-color: #f2f2f2;
  padding: 0 0 50px;
  margin-top: 50px; }
  .full-width-alt:nth-child(2) {
    margin-top: 0; }

.full-width-alt__title {
  font-family: "Montserrat", "Helvetica Neue", "Source Sans Pro", "Helvetica", "Arial", sans-serif;
  font-weight: 700;
  color: #14558f;
  text-align: center; }
  .full-width-alt__title span {
    border-bottom: 3px solid #FFB300;
    color: #14558f;
    padding-bottom: 10px;
    display: inline-block;
    line-height: 1.3; }

h3.full-width-alt__title {
  color: #14558f; }
  h3.full-width-alt__title span {
    border-bottom: 3px solid #43956f;
    color: #14558f;
    padding-bottom: 10px;
    display: inline-block;
    line-height: 1.3; }

@media all and (min-width: 992px) {
  .full-width-alt__title {
    text-align: left; } }

.data-dash {
  margin: 30px auto;
  padding: 0;
  background-color: #f2f2f2; }

.dashboard {
  padding: 50px 2%; }

.dash-card {
  background-color: #ffffff;
  width: 100%;
  height: 280px;
  margin-bottom: 15px;
  padding: 20px; }

.dash-card__hightlight {
  color: #14558f;
  font-weight: 700; }

.dash-select {
  background-color: #212121;
  padding: 5px 5%;
  margin: 0;
  font-size: 1.6rem; }
  .dash-select span.dash-select_text {
    color: #ffffff;
    margin-bottom: 0;
    display: inline; }
  .dash-select .btn-primary {
    margin-left: 0; }

.Select {
  display: inline-table;
  width: 100%;
  z-index: 100;
  margin-bottom: 5px; }
  .Select .Select-control {
    border-radius: 0;
    padding: 2px; }

.Select-placeholder, .Select--single > .Select-control .Select-value {
  padding-top: 3px; }

.backToTop {
  display: inline; }

.btn.backToTop_btn {
  margin-top: 12px;
  display: none;
  border-radius: 0; }

a.backToTop_link {
  color: #ffffff;
  text-decoration: underline;
  display: block; }

@media (min-width: 1200px) {
  .Select {
    width: 60%; }
  .btn.backToTop_btn {
    margin-top: 10px;
    display: inline-block; }
  a.backToTop_link {
    display: none; } }

.section-divider-title {
  color: #535353;
  position: relative;
  z-index: 1;
  margin-bottom: 5px; }
  .section-divider-title:before {
    border-top: 2px solid #dcdcdc;
    content: "";
    margin: 0 auto;
    /* this centers the line to the full width specified */
    position: absolute;
    /* positioning must be absolute here, and relative positioning must be applied to the parent */
    top: 50%;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    z-index: -1; }
  .section-divider-title span {
    /* to hide the lines from behind the text, you have to set the background color the same as the container */
    background: #f2f2f2;
    padding: 0 15px; }

body {
  margin: 0;
  padding: 0;
  font-family: "Helvetica Neue", "Source Sans Pro", "Helvetica", "Arial", sans-serif;
  font-size: 1.5rem; }

p {
  font-size: 1.8rem;
  line-height: 1.5; }

a {
  text-decoration: underline; }

