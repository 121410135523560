@import url("https://fonts.googleapis.com/css?family=Montserrat:300,400,700");
h2.grade-header {
  font-family: "Montserrat", "Helvetica Neue", "Source Sans Pro", "Helvetica", "Arial", sans-serif;
  font-size: 2.7rem;
  background-color: #FFB300;
  color: #ffffff;
  padding: 15px 10px;
  text-align: center; }

h3.chart-title {
  font-family: "Montserrat", "Helvetica Neue", "Source Sans Pro", "Helvetica", "Arial", sans-serif;
  font-weight: 700;
  font-size: 2.3rem;
  border-bottom: 3px solid #FFB300;
  color: #14558f;
  padding-bottom: 10px;
  display: inline-block;
  line-height: 1.3;
  width: 100%;
  text-align: center; }
  h3.chart-title span {
    font-weight: 300;
    font-size: 2.3rem; }

@media all and (min-width: 992px) {
  h2.grade-header {
    font-size: 2.7rem;
    background-color: #FFB300;
    color: #ffffff;
    padding: 25px 5%;
    margin-bottom: 30px; }
    h2.grade-header span {
      font-size: 5rem; }
  h3.chart-title {
    line-height: 1.5;
    border-top: 7px solid #FFB300;
    border-bottom: 7px solid #FFB300;
    padding: 23px 0 23px 20px;
    font-size: 2.7rem;
    text-align: left; }
    h3.chart-title span {
      display: block; } }
