@import url("https://fonts.googleapis.com/css?family=Montserrat:300,400,700");
.dash-select {
  background-color: #212121;
  padding: 5px 5%;
  margin: 0;
  font-size: 1.6rem; }
  .dash-select span.dash-select_text {
    color: #ffffff;
    margin-bottom: 0;
    display: inline; }
  .dash-select .btn-primary {
    margin-left: 0; }

.Select {
  display: inline-table;
  width: 100%;
  z-index: 100;
  margin-bottom: 5px; }
  .Select .Select-control {
    border-radius: 0;
    padding: 2px; }

.Select-placeholder, .Select--single > .Select-control .Select-value {
  padding-top: 3px; }

.backToTop {
  display: inline; }

.btn.backToTop_btn {
  margin-top: 12px;
  display: none;
  border-radius: 0; }

a.backToTop_link {
  color: #ffffff;
  text-decoration: underline;
  display: block; }

@media (min-width: 1200px) {
  .Select {
    width: 60%; }
  .btn.backToTop_btn {
    margin-top: 10px;
    display: inline-block; }
  a.backToTop_link {
    display: none; } }
