@import url("https://fonts.googleapis.com/css?family=Montserrat:300,400,700");
.block-group-item {
  display: inline-block;
  margin: 5px; }

.block-count-chart {
  width: 220px;
  height: 110px;
  position: relative; }

.blockcount {
  float: left;
  height: 6.6%;
  width: 3.3%;
  margin: 0.5%;
  position: relative;
  opacity: 1; }

.color_Yes {
  color: #ffffff;
  background: #43956f; }

.color_No {
  color: #141414;
  background: #dcdcdc; }

.color_alt {
  color: #141414;
  background: rgba(67, 149, 111, 0.4); }
