@import url("https://fonts.googleapis.com/css?family=Montserrat:300,400,700");
.dash-card {
  background-color: #ffffff;
  width: 100%;
  height: 280px;
  margin-bottom: 15px;
  padding: 20px; }

.dash-card__hightlight {
  color: #14558f;
  font-weight: 700; }
