@import url("https://fonts.googleapis.com/css?family=Montserrat:300,400,700");
.list-icon {
  color: #43956f;
  margin-right: 10px; }

ul.icon-list {
  list-style: none;
  margin-left: 0;
  padding-left: 2.5rem;
  text-indent: -1.7rem; }
  ul.icon-list li {
    font-size: 1.8rem;
    padding-left: 1rem;
    margin: 0.8rem 0; }

.full-width-alt {
  background-color: #f2f2f2;
  padding: 0 0 50px;
  margin-top: 50px; }
  .full-width-alt:nth-child(2) {
    margin-top: 0; }

.full-width-alt__title {
  font-family: "Montserrat", "Helvetica Neue", "Source Sans Pro", "Helvetica", "Arial", sans-serif;
  font-weight: 700;
  color: #14558f;
  text-align: center; }
  .full-width-alt__title span {
    border-bottom: 3px solid #FFB300;
    color: #14558f;
    padding-bottom: 10px;
    display: inline-block;
    line-height: 1.3; }

h3.full-width-alt__title {
  color: #14558f; }
  h3.full-width-alt__title span {
    border-bottom: 3px solid #43956f;
    color: #14558f;
    padding-bottom: 10px;
    display: inline-block;
    line-height: 1.3; }

@media all and (min-width: 992px) {
  .full-width-alt__title {
    text-align: left; } }
