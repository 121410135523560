@import url("https://fonts.googleapis.com/css?family=Montserrat:300,400,700");
body {
  margin: 0;
  padding: 0;
  font-family: "Helvetica Neue", "Source Sans Pro", "Helvetica", "Arial", sans-serif;
  font-size: 1.5rem; }

p {
  font-size: 1.8rem;
  line-height: 1.5; }

a {
  text-decoration: underline; }
